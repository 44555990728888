import React, { useEffect, useState, useRef } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal } from "antd";
import ReactToPrint from "react-to-print";
// import { useRouter } from "next/router";
// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
import HeadingBack from "../../HeadingBack";
import { URL } from "../../../Redux/common/url";
// import { allapiAction } from "../../../Redux/common/action";
import { useNavigate, useParams } from "react-router-dom";
import { allapiAction } from "../../../Redux/common/action";
import { toast } from "react-toastify";

function ShopSellComponent({ pagetype }) {
  const pageofget =
    pagetype == "canceled"
      ? "Canceled Sale"
      : pagetype == "confirmed"
        ? "Confirmed Sale"
        : pagetype == "delivered"
          ? "Delivered Sale"
          : pagetype == "out-for-delivery"
            ? "Out for delivery Sale"
            : pagetype == "pending"
              ? "Pending Sale"
              : pagetype == "processing"
                ? "Processing Sale"
                : pagetype == "returned"
                  ? "Returned Sale"
                  : "Shop Sale";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useParams();
  const componentRef = useRef();
  const { confirm } = Modal;
  const page = navigate?.query?.page == null ? 1 : navigate?.query?.page;
  const search = navigate?.query?.search == null ? "" : navigate?.query?.search;

  const order_list = useSelector((state) =>
    state?.allapi?.vendor_order_list ? state?.allapi?.vendor_order_list : {}
  );
  const order_details = useSelector((state) =>
    state?.allapi?.order_details ? state?.allapi?.order_details : {}
  );
  const get_invoice = useSelector((state) =>
    state?.allapi?.get_invoice ? state?.allapi?.get_invoice : {}
  );
  const [pages, setPage] = useState(1);
  const listdata = order_list?.docs ? order_list?.docs : [];

  useEffect(() => {
    if (pagetype) {
      if (pagetype == "main") {
        dispatch(
          allapiAction.getSearchOrdervendorShop({
            page: page,
            key: search,
            status: "",
          })
        );
      } else {
        dispatch(
          allapiAction.getSearchOrdervendorShop({
            page: page,
            key: search,
            status: pagetype,
          })
        );
      }
    } else {
      dispatch(allapiAction.getuserOrdervendor(page));
    }
    return () => {};
  }, [location]);

  const getdataList = async (e) => {
    if (pagetype) {
      if (pagetype == "main") {
        dispatch(
          allapiAction.getSearchOrdervendor({
            page: page,
            key: search,
            status: "",
          })
        );
      } else {
        dispatch(
          allapiAction.getSearchOrdervendor({
            page: page,
            key: search,
            status: pagetype,
          })
        );
      }
    } else {
      dispatch(allapiAction.getuserOrdervendor(page));
    }
  };

  const [statusDataBoxReturn2, setstatusDataBoxReturn2] = useState(false);

  const [amountCollect1, setamountCollect1] = useState({});

  const hendeltochangecollect1 = (e) => {
    setamountCollect1({ ...amountCollect1, [e.target.name]: e.target.value });
  };

  // orderDispatchFromSaler

  const hendletoAddress = async (e) => {
    if (!amountCollect1?.trackingId) {
      return toast?.error("please enter trackingId");
    }
    if (!amountCollect1?.courierCompany) {
      return toast?.error("please enter courier Company ");
    }

    const response = await dispatch(
      allapiAction.orderDispatchFromSaler({
        _id: statusUpTrId?._id,

        trackingId: amountCollect1?.trackingId,
        courierCompanynumber: amountCollect1?.courierCompanynumber,
        courierCompany: amountCollect1?.courierCompany,
      })
    );

    if (response?.success) {
      getdataList();
      setstatusDataBoxReturn2(false);
      setamountCollect1({
        courierCompany: "",
        courierCompanynumber: "",
        trackingId: "",
      });
    }
    // getupdateOfferOrderDetails
  };

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const hendleDelete = (e) => {
    dispatch(allapiAction.deleteProduct({ productId: e, pageNo: page }));
    return () => {};
  };
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);
  const [statusDataBoxView, setstatusDataBoxView] = useState(false);
  const [statusDataBoxReturn, setstatusDataBoxReturn] = useState(false);
  const [productID, setproductID] = useState("");
  const [statusUpTrStatus, setstatusUpTrStatus] = useState("");
  const [statusUpTrId, setstatusUpTrId] = useState("");
  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    setuserData(e);
    setproductID(e);
    dispatch(allapiAction.getuserOrderDetailsShop({ _id: e?._id }));
  };
  const hendleToViewProduct = (e) => {
    setstatusDataBoxView(true);
    setuserData(e);
    setproductID(e);
    dispatch(allapiAction.getuserOrderDetailsShop({ _id: e?._id }));
  };
  const hendleToViewReturn = (e) => {
    setstatusDataBoxReturn(true);
    // setuserData(e);
    setstatusUpTrId(e);
    // dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };
  const hendleSubmitReturn = (e) => {
    setstatusDataBoxReturn(false);
    // dispatch(
    //   allapiAction.cancelOrderByAdmin({
    //     _id: statusUpTrId?._id,
    //     user_id: statusUpTrId?.user_id?._id,
    //     description: statusUpTrStatus,
    //     page: page,
    //   })
    // );
    setstatusUpTrStatus("");
  };

  const shippingCharge = parseInt(order_details?.delivery_charges);
  const totalSubtotalPricegstdd =
    (order_details.sub_total * order_details?.gst) / 100;
  const totalPrice = order_details?.product_price; // Total price inclusive of GST
  const gstRate = order_details?.gst; // GST rate (5%)
  const basePrice = totalPrice / (1 + gstRate / 100);
  const basePriceTotal = basePrice * order_details?.itme_count;
  const perProgst = totalPrice - basePrice;
  const totalgst = order_details?.sub_total - basePriceTotal;
  const totalSubtotalPricegst = totalSubtotalPricegstdd;
  const totalAmount = order_details?.sub_total + shippingCharge;
  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    // dispatch(
    //   allapiAction.addFoundByAdmin({
    //     id: userData?._id,
    //     amount: statusData?.amount,

    //     pageNumber: page,
    //   })
    // );

    setstatusDataBox(false);
  };

  const showModal = (e) => {
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: userId,
    //     status: userId,
    //     pageNumber: page,
    //   })
    // );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    /*  dispatch(
      allapiAction.user_inactive({
        _id: id,
        status: status,
        pageNumber: page,
      })
    ); */
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const hendleToStatus = (id, e) => {
    // dispatch(allapiAction.statusProduct({ productId: id, status: e }));
    return () => {};
  };

  const hendleTocollectModel2 = (e) => {
    setstatusUpTrId(e);
    setstatusDataBoxReturn2(true);
  };
  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Sell Code",
      dataIndex: "sellCode",
      key: "sellCode",
      className: "table_action_col",
    },
    {
      title: "Customer Info",
      dataIndex: "referId",
      key: "referId",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <span className="cursor-pointer coustomNevc">
              {item?.user_id?.fullName}
            </span>
            <a class="d-block title-color" href="tel:7231818743">
              {item?.user_id?.phone}
            </a>
          </>
        );
      },
    },
    // {
    //   title: "Buyer Number",
    //   dataIndex: "phone",
    //   key: "phone",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <span className="cursor-pointer ">{item?.user_id?.phone}</span>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Order Date",
      dataIndex: "createdAt",
      key: "createdAt",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <span className="cursor-pointer">
              {moment(item?.createdAt).format(" DD/MM/YYYY hh:mm A")}
            </span>
          </>
        );
      },
    },
    {
      title: "Total Amount",
      dataIndex: "sub_total",
      key: "sub_total",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <span className="cursor-pointer">₹ {item?.sub_total}</span>
          </>
        );
      },
    },
    // {
    //   title: "Amount",
    //   dataIndex: "sub_total",
    //   key: "sub_total",
    // },
    {
      title: "Order Status",
      dataIndex: "delivery_status",
      key: "delivery_status",
    },
    {
      title: "Order return",
      dataIndex: "orderretune",
      key: "orderretune",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title="Invoice">
              <span className="cursor-pointer  ">
                {item?.orderretune == "true" ? "Yes" : "No"}
              </span>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Invoice">
              <sapn class="btn btn-outline-success square-btn btn-sm mr-1">
                Invoice
              </sapn>
            </Tooltip> */}

            <Tooltip title="Invoice">
              <span
                className="cursor-pointer btn btn-outline-primary mr-5"
                onClick={() => hendleToViewM(item)}
              >
                Invoice
              </span>
            </Tooltip>

            {/* <Tooltip title="View">
              <span
                className="cursor-pointer btn btn-outline-success"
                // onClick={() => hendleToViewM(item)}
                onClick={() => hendleToViewProduct(item)}
              >
                <img
                  src="https://ganeshji.online/public/assets/back-end/img/eye.svg"
                  class="svg"
                  alt=""
                />
              </span>
            </Tooltip> */}

            {/* <Tooltip title="Dispatch">
              <button
                className="filter-button ml-3  mt-5"
                onClick={() => hendleTocollectModel2(item)}
              >
                Dispatch
              </button>
            </Tooltip> */}
            {/* <Tooltip title="View">
              <sapn
                class="btn btn-outline--primary square-btn btn-sm mr-1"
                onClick={() => hendleToViewProduct(item)}
              >
                <img
                  src="https://ganeshji.online/public/assets/back-end/img/eye.svg"
                  class="svg"
                  alt=""
                />
              </sapn>
            </Tooltip> */}
            {/* <Tooltip title="Return Order"></Tooltip> */}
          </>
        );
      },
    },
  ];
  const pageofget2 =
    pagetype == "canceled"
      ? "/canceled"
      : pagetype == "confirmed"
        ? "/confirmed"
        : pagetype == "delivered"
          ? "/delivered"
          : pagetype == "out-for-delivery"
            ? "/out-for-delivery"
            : pagetype == "pending"
              ? "/pending"
              : pagetype == "processing"
                ? "/processing"
                : pagetype == "returned"
                  ? "/returned"
                  : "";
  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/seller/sale" + pageofget2 + "?page=" + 1 + "&search=" + e);
  };

  const hendlePahegi = (e) => {
    setPage(e);
    navigate("/seller/sale" + pageofget2 + "?page=" + e + "&&search=" + search);
  };

  return (
    <div>
      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <Modal
        title="Dispatch order"
        centered
        open={statusDataBoxReturn2}
        onOk={() => hendletoAddress(false)}
        onCancel={() => setstatusDataBoxReturn2(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Courier Company</Col>
            <Col span={24}>
              <Input
                name="courierCompany"
                type="text"
                placeholder="enter courier company"
                value={amountCollect1?.courierCompany}
                onChange={(e) => {
                  hendeltochangecollect1(e);
                }}
              />
            </Col>
            <Col span={24}>Courier Company number</Col>
            <Col span={24}>
              <Input
                name="courierCompanynumber"
                type="text"
                placeholder="enter courier company number"
                value={amountCollect1?.courierCompanynumber}
                onChange={(e) => {
                  hendeltochangecollect1(e);
                }}
              />
            </Col>
            <Col span={24}>Tracking Id</Col>
            <Col span={24}>
              <Input
                name="trackingId"
                type="text"
                placeholder="enter tracking Id"
                value={amountCollect1?.trackingId}
                onChange={(e) => {
                  hendeltochangecollect1(e);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        title="Inovice"
        centered
        open={statusDataBox}
        onCancel={() => setstatusDataBox(false)}
        width={800}
      >
        <div
          id="kt_app_content"
          className="app-content flex-column-fluid"
          ref={componentRef}
        >
          <div class="container mt-30">
            <div class="row">
              <div class="col-12 text-center bg-secondary py-3">
                <h3>
                  <b>Invoice Paper</b>
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center py-2 bg-light">
                <p class="p-0 m-0">
                  {" "}
                  <b>Invoice No</b> : {order_details?.sellCode}
                </p>
                <p class="p-0 m-0">
                  <b>Date</b> :{/* 04 Mar, 2023 */}
                  {moment(productID?.createdAt).format("DD MMM,  YYYY")}
                </p>
                <p class="p-0 m-0">
                  <b>Vendor</b> :{" "}
                  {order_details && order_details?.vendor_id?.displayName}
                </p>
                <p class="text-left">
                  <b> User Id </b>: {order_details?.user_id?.referId}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h4>
                  <b>Client Information:</b>
                </h4>
                <p class="p-0 m-0">
                  <b>Full Name</b>: {order_details?.user_id?.fullName}
                </p>
                {/* <p class="p-0 m-0">
                  <b>Last Name:</b>
                </p> */}
              </div>
              <div class="col-6">
                <h4>
                  <b>Peyment Details :</b>
                </h4>
                <p class="p-0 m-0">
                  <b>Payment Status</b> : Fully Paid
                </p>
                <p class="p-0 m-0">
                  <b>Payment Method</b> :  Vendor Wallet
                </p>
              </div>
            </div>
            <div class="row mx-2">
              <div class="col-12 mt-2 text-center text-light bg-danger py-3">
                <h3>
                  <b>Payment Invoice</b>
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                {/* <table class="table table-responsive">
                  <thead class="thead-dark" style={{minWidth:"100%"}}>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">First</th>
                      <th scope="col">Last</th>
                      <th scope="col">Handle</th>
                      <th scope="col">Handle</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Mark</td>
                      <td>Otto</td>
                      <td>@mdo</td>
                      <td>@mdo</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Jacob</td>
                      <td>Thornton</td>
                      <td>@fat</td>
                      <td>@fat</td>
                    </tr>
                  </tbody>
                </table> */}
                {/* <div className="table-responsive border-bottom mb-9">
                  <table className="table  align-middle table-row-dashed fs-6 gy-5 mb-0">
                    <thead>
                      <tr className="border-bottom thead-dark fs-6 fw-bold text-muted">
                        <th>S.No</th>
                        <th className="min-w-175px pb-2">Item</th>
                        <th className="min-w-70px text-end pb-2">Color</th>
                        <th className="min-w-70px text-end pb-2">Size</th>
                        <th className="min-w-70px text-end pb-2">Unit Cost</th>
                        <th className="min-w-80px text-end pb-2">Quantity</th>
                        <th className="min-w-80px text-end pb-2">
                          Net Amount(₹)
                        </th>
                        <th className="min-w-80px text-end pb-2">Tax(₹)</th>

                        <th className="min-w-100px text-end pb-2">Amount</th>
                      </tr>
                    </thead>
                    <tbody className="fw-semibold invotb text-gray-600">
                      {order_details?.orderproductsList &&
                        order_details?.orderproductsList?.map((data, i) => {
                          const totalPrice = data?.product_price;
                          const gstRate = data?.gst;
                          const basePrice = totalPrice / (1 + gstRate / 100);
                          const perProgst = totalPrice - basePrice;

                          const netAmount = basePrice * data?.itme_count;

                          const taxAmount = perProgst * data?.itme_count;

                          return (
                            <tr>
                              <td className="">{i + 1}</td>
                              <td>
                                <div className="d-flex ">
                                  <div className="">
                                    <div className="fw-bold">
                                      {data?.product_names}
                                    </div>
                                    <div className="fs-7 text-muted"></div>
                                  </div>
                                </div>
                              </td>

                              <td className="text-end">
                                {" "}
                                {data?.colorId?.color}
                              </td>
                              <td className="text-end">
                                {" "}
                                {data?.sizeId?.size}
                              </td>
                              <td className="text-end">
                                {basePrice?.toFixed(2)}
                              </td>
                              <td className="text-end"> {data?.itme_count}</td>
                              <td className="text-end">
                                {" "}
                                {netAmount?.toFixed(2)}
                              </td>

                              <td className="text-end">
                                {" "}
                                {taxAmount?.toFixed(2)}
                              </td>

                              <td className="text-end">₹ {data?.sub_total}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div> */}
                <div className="d-flex justify-content-between flex-column">
                  <div className="table-responsive border-bottom mb-9">
                    <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                      <thead>
                        <tr className="border-bottom fs-6 fw-bold text-muted">
                          <th>S.No</th>
                          <th className="min-w-175px pb-2">Item</th>
                          {/* <th className="min-w-70px text-end pb-2">
                                  Color
                                </th>
                                <th className="min-w-70px text-end pb-2">
                                  Size
                                </th>
                                <th className="min-w-70px text-end pb-2">
                                  Unit Cost
                                </th>
                                <th className="min-w-80px text-end pb-2">
                                  Quantity
                                </th>
                                <th className="min-w-80px text-end pb-2">
                                  Net Amount(₹)
                                </th>
                                <th className="min-w-80px text-end pb-2">
                                  Tax(₹)
                                </th> */}

                          <th className="min-w-100px text-end pb-2">Amount</th>
                        </tr>
                      </thead>
                      <tbody className="fw-semibold invotb text-gray-600">
                        <tr>
                          <td className="">1</td>
                          <td>
                            <div className="d-flex ">
                              <div className="">
                                <div className="fw-bold">
                                  {order_details?.productName}
                                </div>
                                <div className="fs-7 text-muted"></div>
                              </div>
                            </div>
                          </td>
                          {/* <td className="text-end">
                                  {" "}
                                  {order_details?.colorId?.color}
                                </td>
                                <td className="text-end">
                                  {" "}
                                  {order_details?.sizeId?.size}
                                </td>
                                <td className="text-end">
                                  {" "}
                                  {basePrice?.toFixed(2)}
                                </td>
                                <td className="text-end">
                                  {" "}
                                  {order_details?.itme_count}
                                </td>
                                <td className="text-end">
                                  {" "}
                                  {basePrice?.toFixed(2) *
                                    order_details?.itme_count}
                                </td>
                                <td className="text-end">
                                  {" "}
                                  {perProgst?.toFixed(2) *
                                    order_details?.itme_count}
                                </td> */}
                          <td className="text-end">
                            ₹ {order_details?.sub_total}
                          </td>
                        </tr>
                        {/* <tr>
                                <td colSpan={8} className="text-end">
                                  Shipping
                                </td>
                                <td className="text-end">₹ {shippingCharge}</td>
                              </tr> */}
                        {/* <tr>
                          <td
                            colSpan={2}
                            className="fs-3 text-dark fw-bold text-end"
                          >
                            Grand Total
                          </td>
                          <td className="text-dark fs-3 fw-bolder text-end">
                            ₹ {order_details?.sub_total}
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 bg-secondary text-black">
                <h4 class="py-2">
                  <b>Address</b>
                </h4>
                <p class="p-0 m-0  text-black">
                  {order_details?.user_id?.address1}
                </p>
                <p class="p-0 m-0  text-black">
                  ZIP : {order_details?.user_id?.pincode}
                </p>
                <p class="p-0 m-0  text-black">
                  Phone :{order_details?.user_id?.phone}
                </p>
                <p class="p-0 m-0  text-black">
                  E-mail : {order_details?.user_id?.email}
                </p>
              </div>
              <div class="col-6 text-right">
                {/* <h5 class="py-2">
                  Sub Total Amount :₹
                  {totalSubtotalPricegstdd?.toFixed(2)}
                </h5>
                <h5 class="py-2">
                  Tax :₹{(totalAmount - totalSubtotalPricegstdd)?.toFixed(2)}
                </h5>
                <h5 class="py-2">Shipping :₹{shippingCharge}</h5> */}
                <h4 class="py-2">Grand Total :₹{order_details?.sub_total}</h4>
              </div>
            </div>
          </div>

       
        </div>
        <div className="row">
          <div className="col-6"></div>
          <div className="col-6">
            <ReactToPrint
              trigger={() => (
                <Button variant="primary" onClick={hendleDelete}>
                  Print / Download
                </Button>
              )}
              content={() => componentRef.current}
            />
          </div>
        </div>
      </Modal>
      <Modal
        title="Order Details"
        centered
        open={statusDataBoxView}
        onOk={() => setstatusDataBoxView(false)}
        onCancel={() => setstatusDataBoxView(false)}
        width={1200}
      >
        <div className="">
          <div>
            <div className="row">
              <div className="col-4">
                <h6> id : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?._id}</p>
              </div>

              <div className="col-4">
                <h6>payment mode name : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.payment_mode_name}</p>
              </div>

              <div className="col-4">
                <h6>sub total : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.sub_total}</p>
              </div>
            </div>
            <hr />
            <h3>user Details</h3>
            <div className="row">
              <div className="col-4">
                <h6> user id : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.user_id?._id}</p>
              </div>

              <div className="col-4">
                <h6>user reffer id : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.user_id?.referId}</p>
              </div>

              <div className="col-4">
                <h6>full name : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.user_id?.fullName}</p>
              </div>
              <div className="col-4">
                <h6>Email : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.user_id?.email}</p>
              </div>
            </div>
            <hr />
            <h3>shipping address</h3>
            <div className="row">
              <div className="col-4">
                <h6> name : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.address_id?.name}</p>
              </div>

              <div className="col-4">
                <h6>email : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.email}</p>
              </div>

              <div className="col-4">
                <h6>phone : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.phone}</p>
              </div>
              <div className="col-4">
                <h6>address : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.address}</p>
              </div>
              <div className="col-4">
                <h6>city : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.address_id?.city}</p>
              </div>
              <div className="col-4">
                <h6>state : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.address_id?.state}</p>
              </div>
              <div className="col-4">
                <h6>pincode : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.pincode}</p>
              </div>
            </div>

            <hr />
            <h3>Order Product</h3>
            <div>
              <div className="row">
                <div className="col-3">
                  <h6>sub order id</h6>
                </div>
                <div className="col-3">
                  <p>{order_details?.sub_order_id}</p>
                </div>
                <div className="col-3">
                  <h6>name</h6>
                </div>
                <div className="col-3">
                  <p>{order_details?.product_names}</p>
                </div>
                <div className="col-3">
                  <h6>Color</h6>
                </div>
                <div className="col-3">
                  <p>{order_details?.colorId?.color}</p>
                </div>
                <div className="col-3">
                  <h6>size</h6>
                </div>
                <div className="col-3">
                  <p>{order_details?.sizeId?.size}</p>
                </div>

                <div className="col-3">
                  <h6>product price</h6>
                </div>
                <div className="col-3">
                  <p> ₹ {order_details?.product_price}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p></p>
                </div>
                <div className="col-3">
                  <h6>item Count</h6>
                </div>
                <div className="col-3">
                  <p>x {order_details?.itme_count}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p></p>
                </div>
                <div className="col-3">
                  <h6>sub total</h6>
                </div>
                <div className="col-3">
                  <p> ₹ {order_details?.sub_total}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p></p>
                </div>
                <div className="col-3">
                  <h6>Shipping</h6>
                </div>
                <div className="col-3">
                  <p>₹ {shippingCharge}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p> </p>
                </div>
                <div className="col-3">
                  <h6>Gst</h6>
                </div>
                <div className="col-3">
                  <p> ₹ {totalSubtotalPricegstdd}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p> </p>
                </div>
                <div className="col-3">
                  <h6>Grant total</h6>
                </div>
                <div className="col-3">
                  <p> ₹ {totalAmount}</p>
                </div>
                <div className="col-3">
                  <h6>feture image</h6>
                </div>
                <div className="col-3">
                  <div style={{ width: "100px" }}>
                    <img
                      src={
                        URL.API_BASE_URL + order_details?.colorId?.featureImage
                      }
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title="Return order"
        centered
        open={statusDataBoxReturn}
        onOk={() => hendleSubmitReturn(false)}
        onCancel={() => setstatusDataBoxReturn(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Description</Col>
            <Col span={24}>
              <Input
                name="description"
                placeholder="enter description..."
                value={statusUpTrStatus}
                onChange={(e) => {
                  setstatusUpTrStatus(e?.target?.value);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={pageofget}
            subHeading={`Total ${order_list?.totalDocs || 0} Sale`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
          />
        </Col>
      </div>

      <main id="content" role="main" class="main pointer-event">
        <div class="content container-fluid">
          <div>
            <div class="card">
              <div class="card"></div>

              <div class="card-body">
                <div className="tableResponsive mt-10">
                  <Table
                    className={"table antd_table"}
                    dataSource={listdata}
                    columns={columns}
                    pagination={{
                      current: parseInt(page),
                      pageSize: 10,
                      onChange(current, pageSize) {
                        hendlePahegi(current);
                      },
                      hideOnSinglePage: true,
                      total: order_list?.totalDocs ? order_list?.totalDocs : 0,
                    }}
                  />
                </div>

                {/* <!-- Table --> */}
                {/* <div class="table-responsive datatable-custom">
                  <table
                    class="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                    style={{ textAlign: "left" }}
                  >
                    <thead class="thead-light thead-50 text-capitalize">
                      <tr>
                        <th class="">SL</th>
                        <th>Order ID</th>
                        <th>Order Date</th>
                        <th>Customer Info</th>
                        <th>Store</th>
                        <th class="text-right">Total Amount</th>
                        <th class="text-center">Order Status </th>
                        <th class="text-center">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr class="status-pending class-all">
                        <td class="">1</td>
                        <td>
                          <a
                            class="title-color"
                            href="https://ganeshji.online/admin/orders/details/100009"
                          >
                            100009
                          </a>
                        </td>
                        <td>
                          <div>21 May 2024,</div>
                          <div>10:36 AM</div>
                        </td>
                        <td>
                          <a
                            class="text-body text-capitalize"
                            href="https://ganeshji.online/admin/orders/details/100009"
                          >
                            <strong class="title-name">admin sharma</strong>
                          </a>
                          <a class="d-block title-color" href="tel:7231818743">
                            7231818743
                          </a>
                        </td>
                        <td>
                          <span class="store-name font-weight-medium">
                            In-House
                          </span>
                        </td>
                        <td class="text-right">
                          <div>₹1,835.20</div>

                          <span class="badge text-danger fz-12 px-0">
                            Unpaid
                          </span>
                        </td>
                        <td class="text-center text-capitalize">
                          <span class="badge badge-soft-info fz-12">
                            Pending
                          </span>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center gap-2">
                            <a
                              class="btn btn-outline--primary square-btn btn-sm mr-1"
                              title="View"
                              href="https://ganeshji.online/admin/orders/details/100009"
                            >
                              <img
                                src="https://ganeshji.online/public/assets/back-end/img/eye.svg"
                                class="svg"
                                alt=""
                              />
                            </a>
                            <a
                              class="btn btn-outline-success square-btn btn-sm mr-1"
                              target="_blank"
                              title="Invoice"
                              href="https://ganeshji.online/admin/orders/generate-invoice/100009"
                            >
                              <i class="tio-download-to"></i>
                            </a>
                          </div>
                        </td>
                      </tr>

                      <tr class="status-pending class-all">
                        <td class="">2</td>
                        <td>
                          <a
                            class="title-color"
                            href="https://ganeshji.online/admin/orders/details/100008"
                          >
                            100008
                          </a>
                        </td>
                        <td>
                          <div>04 Oct 2023,</div>
                          <div>09:07 AM</div>
                        </td>
                        <td>
                          <a
                            class="text-body text-capitalize"
                            href="https://ganeshji.online/admin/orders/details/100008"
                          >
                            <strong class="title-name">ram singh</strong>
                          </a>
                          <a
                            class="d-block title-color"
                            href="tel:917976065962"
                          >
                            917976065962
                          </a>
                        </td>
                        <td>
                          <span class="store-name font-weight-medium">
                            In-House
                          </span>
                        </td>
                        <td class="text-right">
                          <div>₹906.00</div>

                          <span class="badge text-danger fz-12 px-0">
                            Unpaid
                          </span>
                        </td>
                        <td class="text-center text-capitalize">
                          <span class="badge badge-soft-info fz-12">
                            Pending
                          </span>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center gap-2">
                            <a
                              class="btn btn-outline--primary square-btn btn-sm mr-1"
                              title="View"
                              href="https://ganeshji.online/admin/orders/details/100008"
                            >
                              <img
                                src="https://ganeshji.online/public/assets/back-end/img/eye.svg"
                                class="svg"
                                alt=""
                              />
                            </a>
                            <a
                              class="btn btn-outline-success square-btn btn-sm mr-1"
                              target="_blank"
                              title="Invoice"
                              href="https://ganeshji.online/admin/orders/generate-invoice/100008"
                            >
                              <i class="tio-download-to"></i>
                            </a>
                          </div>
                        </td>
                      </tr>

                      <tr class="status-pending class-all">
                        <td class="">3</td>
                        <td>
                          <a
                            class="title-color"
                            href="https://ganeshji.online/admin/orders/details/100007"
                          >
                            100007
                          </a>
                        </td>
                        <td>
                          <div>07 Sep 2023,</div>
                          <div>04:08 AM</div>
                        </td>
                        <td>
                          <a
                            class="text-body text-capitalize"
                            href="https://ganeshji.online/admin/orders/details/100007"
                          >
                            <strong class="title-name">Nipun Agarwal</strong>
                          </a>
                          <a class="d-block title-color" href="tel:7733878649">
                            7733878649
                          </a>
                        </td>
                        <td>
                          <span class="store-name font-weight-medium">
                            In-House
                          </span>
                        </td>
                        <td class="text-right">
                          <div>₹899.00</div>

                          <span class="badge text-danger fz-12 px-0">
                            Unpaid
                          </span>
                        </td>
                        <td class="text-center text-capitalize">
                          <span class="badge badge-soft-info fz-12">
                            Pending
                          </span>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center gap-2">
                            <a
                              class="btn btn-outline--primary square-btn btn-sm mr-1"
                              title="View"
                              href="https://ganeshji.online/admin/orders/details/100007"
                            >
                              <img
                                src="https://ganeshji.online/public/assets/back-end/img/eye.svg"
                                class="svg"
                                alt=""
                              />
                            </a>
                            <a
                              class="btn btn-outline-success square-btn btn-sm mr-1"
                              target="_blank"
                              title="Invoice"
                              href="https://ganeshji.online/admin/orders/generate-invoice/100007"
                            >
                              <i class="tio-download-to"></i>
                            </a>
                          </div>
                        </td>
                      </tr>

                      <tr class="status-pending class-all">
                        <td class="">4</td>
                        <td>
                          <a
                            class="title-color"
                            href="https://ganeshji.online/admin/orders/details/100006"
                          >
                            100006
                          </a>
                        </td>
                        <td>
                          <div>07 Sep 2023,</div>
                          <div>04:08 AM</div>
                        </td>
                        <td>
                          <a
                            class="text-body text-capitalize"
                            href="https://ganeshji.online/admin/orders/details/100006"
                          >
                            <strong class="title-name">Nipun Agarwal</strong>
                          </a>
                          <a class="d-block title-color" href="tel:7733878649">
                            7733878649
                          </a>
                        </td>
                        <td>
                          <span class="store-name font-weight-medium">
                            Golden Computers
                          </span>
                        </td>
                        <td class="text-right">
                          <div>₹900.00</div>

                          <span class="badge text-danger fz-12 px-0">
                            Unpaid
                          </span>
                        </td>
                        <td class="text-center text-capitalize">
                          <span class="badge badge-soft-info fz-12">
                            Pending
                          </span>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center gap-2">
                            <a
                              class="btn btn-outline--primary square-btn btn-sm mr-1"
                              title="View"
                              href="https://ganeshji.online/admin/orders/details/100006"
                            >
                              <img
                                src="https://ganeshji.online/public/assets/back-end/img/eye.svg"
                                class="svg"
                                alt=""
                              />
                            </a>
                            <a
                              class="btn btn-outline-success square-btn btn-sm mr-1"
                              target="_blank"
                              title="Invoice"
                              href="https://ganeshji.online/admin/orders/generate-invoice/100006"
                            >
                              <i class="tio-download-to"></i>
                            </a>
                          </div>
                        </td>
                      </tr>

                      <tr class="status-delivered class-all">
                        <td class="">5</td>
                        <td>
                          <a
                            class="title-color"
                            href="https://ganeshji.online/admin/orders/details/100005"
                          >
                            100005
                          </a>
                        </td>
                        <td>
                          <div>04 Sep 2023,</div>
                          <div>01:18 PM</div>
                        </td>
                        <td>
                          <a
                            class="text-body text-capitalize"
                            href="https://ganeshji.online/admin/orders/details/100005"
                          >
                            <strong class="title-name">test test</strong>
                          </a>
                          <a class="d-block title-color" href="tel:8767657667">
                            8767657667
                          </a>
                        </td>
                        <td>
                          <span class="store-name font-weight-medium">
                            Golden Computers
                          </span>
                        </td>
                        <td class="text-right">
                          <div>₹900.00</div>

                          <span class="badge text-success fz-12 px-0">
                            Paid
                          </span>
                        </td>
                        <td class="text-center text-capitalize">
                          <span class="badge badge-soft-success fz-12">
                            Delivered
                          </span>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center gap-2">
                            <a
                              class="btn btn-outline--primary square-btn btn-sm mr-1"
                              title="View"
                              href="https://ganeshji.online/admin/orders/details/100005"
                            >
                              <img
                                src="https://ganeshji.online/public/assets/back-end/img/eye.svg"
                                class="svg"
                                alt=""
                              />
                            </a>
                            <a
                              class="btn btn-outline-success square-btn btn-sm mr-1"
                              target="_blank"
                              title="Invoice"
                              href="https://ganeshji.online/admin/orders/generate-invoice/100005"
                            >
                              <i class="tio-download-to"></i>
                            </a>
                          </div>
                        </td>
                      </tr>

                      <tr class="status-delivered class-all">
                        <td class="">6</td>
                        <td>
                          <a
                            class="title-color"
                            href="https://ganeshji.online/admin/orders/details/100004"
                          >
                            100004
                          </a>
                        </td>
                        <td>
                          <div>03 Sep 2023,</div>
                          <div>04:15 PM</div>
                        </td>
                        <td>
                          <a
                            class="text-body text-capitalize"
                            href="https://ganeshji.online/admin/orders/details/100004"
                          >
                            <strong class="title-name">Nipun Agarwal</strong>
                          </a>
                          <a class="d-block title-color" href="tel:7733878649">
                            7733878649
                          </a>
                        </td>
                        <td>
                          <span class="store-name font-weight-medium">
                            Golden Computers
                          </span>
                        </td>
                        <td class="text-right">
                          <div>₹900.00</div>

                          <span class="badge text-success fz-12 px-0">
                            Paid
                          </span>
                        </td>
                        <td class="text-center text-capitalize">
                          <span class="badge badge-soft-success fz-12">
                            Delivered
                          </span>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center gap-2">
                            <a
                              class="btn btn-outline--primary square-btn btn-sm mr-1"
                              title="View"
                              href="https://ganeshji.online/admin/orders/details/100004"
                            >
                              <img
                                src="https://ganeshji.online/public/assets/back-end/img/eye.svg"
                                class="svg"
                                alt=""
                              />
                            </a>
                            <a
                              class="btn btn-outline-success square-btn btn-sm mr-1"
                              target="_blank"
                              title="Invoice"
                              href="https://ganeshji.online/admin/orders/generate-invoice/100004"
                            >
                              <i class="tio-download-to"></i>
                            </a>
                          </div>
                        </td>
                      </tr>

                      <tr class="status-delivered class-all">
                        <td class="">7</td>
                        <td>
                          <a
                            class="title-color"
                            href="https://ganeshji.online/admin/orders/details/100003"
                          >
                            100003
                          </a>
                        </td>
                        <td>
                          <div>03 Sep 2023,</div>
                          <div>03:07 PM</div>
                        </td>
                        <td>
                          <a
                            class="text-body text-capitalize"
                            href="https://ganeshji.online/admin/orders/details/100003"
                          >
                            <strong class="title-name">Nipun Agarwal</strong>
                          </a>
                          <a class="d-block title-color" href="tel:7733878649">
                            7733878649
                          </a>
                        </td>
                        <td>
                          <span class="store-name font-weight-medium">
                            Golden Computers
                          </span>
                        </td>
                        <td class="text-right">
                          <div>₹900.00</div>

                          <span class="badge text-success fz-12 px-0">
                            Paid
                          </span>
                        </td>
                        <td class="text-center text-capitalize">
                          <span class="badge badge-soft-success fz-12">
                            Delivered
                          </span>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center gap-2">
                            <a
                              class="btn btn-outline--primary square-btn btn-sm mr-1"
                              title="View"
                              href="https://ganeshji.online/admin/orders/details/100003"
                            >
                              <img
                                src="https://ganeshji.online/public/assets/back-end/img/eye.svg"
                                class="svg"
                                alt=""
                              />
                            </a>
                            <a
                              class="btn btn-outline-success square-btn btn-sm mr-1"
                              target="_blank"
                              title="Invoice"
                              href="https://ganeshji.online/admin/orders/generate-invoice/100003"
                            >
                              <i class="tio-download-to"></i>
                            </a>
                          </div>
                        </td>
                      </tr>

                      <tr class="status-delivered class-all">
                        <td class="">8</td>
                        <td>
                          <a
                            class="title-color"
                            href="https://ganeshji.online/admin/orders/details/100002"
                          >
                            100002
                          </a>
                        </td>
                        <td>
                          <div>03 Sep 2023,</div>
                          <div>02:54 PM</div>
                        </td>
                        <td>
                          <a
                            class="text-body text-capitalize"
                            href="https://ganeshji.online/admin/orders/details/100002"
                          >
                            <strong class="title-name">Nipun Agarwal</strong>
                          </a>
                          <a class="d-block title-color" href="tel:7733878649">
                            7733878649
                          </a>
                        </td>
                        <td>
                          <span class="store-name font-weight-medium">
                            Golden Computers
                          </span>
                        </td>
                        <td class="text-right">
                          <div>₹900.00</div>

                          <span class="badge text-success fz-12 px-0">
                            Paid
                          </span>
                        </td>
                        <td class="text-center text-capitalize">
                          <span class="badge badge-soft-success fz-12">
                            Delivered
                          </span>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center gap-2">
                            <a
                              class="btn btn-outline--primary square-btn btn-sm mr-1"
                              title="View"
                              href="https://ganeshji.online/admin/orders/details/100002"
                            >
                              <img
                                src="https://ganeshji.online/public/assets/back-end/img/eye.svg"
                                class="svg"
                                alt=""
                              />
                            </a>
                            <a
                              class="btn btn-outline-success square-btn btn-sm mr-1"
                              target="_blank"
                              title="Invoice"
                              href="https://ganeshji.online/admin/orders/generate-invoice/100002"
                            >
                              <i class="tio-download-to"></i>
                            </a>
                          </div>
                        </td>
                      </tr>

                      <tr class="status-pending class-all">
                        <td class="">9</td>
                        <td>
                          <a
                            class="title-color"
                            href="https://ganeshji.online/admin/orders/details/100001"
                          >
                            100001
                          </a>
                        </td>
                        <td>
                          <div>26 Aug 2023,</div>
                          <div>09:48 AM</div>
                        </td>
                        <td>
                          <a
                            class="text-body text-capitalize"
                            href="https://ganeshji.online/admin/orders/details/100001"
                          >
                            <strong class="title-name">vijay2 2</strong>
                          </a>
                          <a class="d-block title-color" href="tel:9999999999">
                            9999999999
                          </a>
                        </td>
                        <td>
                          <span class="store-name font-weight-medium">
                            In-House
                          </span>
                        </td>
                        <td class="text-right">
                          <div>₹538.00</div>

                          <span class="badge text-danger fz-12 px-0">
                            Unpaid
                          </span>
                        </td>
                        <td class="text-center text-capitalize">
                          <span class="badge badge-soft-info fz-12">
                            Pending
                          </span>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center gap-2">
                            <a
                              class="btn btn-outline--primary square-btn btn-sm mr-1"
                              title="View"
                              href="https://ganeshji.online/admin/orders/details/100001"
                            >
                              <img
                                src="https://ganeshji.online/public/assets/back-end/img/eye.svg"
                                class="svg"
                                alt=""
                              />
                            </a>
                            <a
                              class="btn btn-outline-success square-btn btn-sm mr-1"
                              target="_blank"
                              title="Invoice"
                              href="https://ganeshji.online/admin/orders/generate-invoice/100001"
                            >
                              <i class="tio-download-to"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div> */}
                {/* <!-- End Table --> */}

                {/* <!-- Pagination --> */}
                <div class="table-responsive mt-4">
                  <div class="d-flex justify-content-lg-end">
                    {/* <!-- Pagination --> */}
                  </div>
                </div>
                {/* <!-- End Pagination --> */}
              </div>
            </div>

            <div class="js-nav-scroller hs-nav-scroller-horizontal d-none">
              <span class="hs-nav-scroller-arrow-prev d-none">
                <a class="hs-nav-scroller-arrow-link" href="javascript:;">
                  <i class="tio-chevron-left"></i>
                </a>
              </span>

              <span class="hs-nav-scroller-arrow-next d-none">
                <a class="hs-nav-scroller-arrow-link" href="javascript:;">
                  <i class="tio-chevron-right"></i>
                </a>
              </span>

              <ul class="nav nav-tabs page-header-tabs">
                <li class="nav-item">
                  <a class="nav-link active" href="#">
                    Order list
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default ShopSellComponent;
