import Boxes2 from "./Boxes2";
import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { Chart } from "react-google-charts";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../../Redux/common/action";
import { URL } from "../../../Redux/common/url";
import { AsyncPaginate } from "react-select-async-paginate";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
// import { allapiAction } from "@/Redux/common/action";

function Deshboard() {
  const dispatch = useDispatch();
  const get_category_sale = useSelector((state) =>
    state?.allapi?.get_category_sale ? state?.allapi?.get_category_sale : []
  );
  const deshboard = useSelector((state) =>
    state?.allapi?.deshboard ? state?.allapi?.deshboard : {}
  );

  const get_profile = useSelector((state) =>
    state?.allapi?.get_profile ? state?.allapi?.get_profile : {}
  );

  console.log(get_profile);
  useEffect(() => {
    dispatch(allapiAction.vendordeshBoradData({}));
    dispatch(allapiAction.getprofile());
    return () => {};
  }, []);
  const data = get_category_sale;
  console.log(get_category_sale);
  const [userselete, setuserselete] = useState(null);

  const loadOptionsPalyer = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchingUserForSall?key=${search}&page=${pages?.page}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.fullName + ", " + data?.referId;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };

  const navigate = useNavigate();
  const hendelToprocess = (e) => {
    if (userselete) {
      navigate(`/seller/process-order/${userselete?._id}`);
    } else {
      toast?.error("please select any one user");
    }
  };

  const hendleToWithdwaralAmount = async (e) => {
    dispatch(allapiAction.WithdrawalsrequsetVendor({ amount: 10 }));
  };

  return (
    <div>
      {/* <Row gutter={[20, 20]} type="flex">
        <Col lg={24} md={24} sm={24} xs={24}>
          <div className=" p-10">
            <div className="dashboardBoxShad b2s m-5 mt-10  mb-20">
              <div className="flex justify-content-between">
                <div>
                  <div className="fw-600">Business Analytics</div>
                </div>
              </div>
              <Boxes2 />
            </div>
            <div className="dashboardBoxShad b2s m-5 mt-10  mb-20">
              <div className="flex justify-content-between">
                <div>
                  <div className="fw-600">Seller Wallet</div>
                </div>
              </div>
              <Row gutter={[20, 20]} type="flex">
                <Col lg={6} md={24} sm={24} xs={24}>
                  <Row gutter={[0, 20]}>
                    <Col lg={24} md={24} sm={24} xs={24}>
                      <div className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer">
                        <div className="flex justify-content-between align-item-center">
                          <div className="relative boxHeadingTotal fw-600">
                            Pending Order
                          </div>
                        </div>
                        <div className="flex justify-content-end align-item-center">
                          <div className="priceText">0</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={18} md={24} sm={24} xs={24}>
                  <Row gutter={[0, 20]}>
                    <Col lg={6} md={24} sm={24} xs={24}>
                      <div className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer">
                        <div className="flex justify-content-between align-item-center">
                          <div className="relative boxHeadingTotal fw-600">
                            Pending Order
                          </div>
                        </div>
                        <div className="flex justify-content-end align-item-center">
                          <div className="priceText">0</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="dashboardBoxShad b2s m-5 mt-10  mb-10">
              <div className="flex justify-content-between">
                <div>
                  <div className="fw-600">Total Sale</div>
                </div>
                <div className="flex justify-content-end gap20 align-items-center">
                  <div className="flex gap30">
                    <div className="relative">
                      <span className="ct_chart_status beforeActive">
                        Category
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <Chart
                chartType="Bar"
                width="100%"
                height="400px"
                data={data}
                options={{
                  fontName: "Open Sans",
                  fontSize: 14,
                  color: "#212529",
                  tooltip: { isHtml: true },
                  legend: { position: "none" },
                  colors: ["#9365b8", "#1559a2"],
                  curveType: "function",
                  hAxis: {
                    textStyle: {
                      whiteSpace: "wrap",
                      fontName: "Open Sans",
                      fontSize: 14,
                      color: "#212529",
                    },
                  },
                  chartArea: {
                    left: 70,
                    top: 10,
                    bottom: 40,
                    width: "100%",
                    height: "100%",
                  },
                }}
              />
            </div>
          </div>
        </Col>
      </Row> */}

      <main id="content" role="main" class="main pointer-event dash">
        {/* <!-- Content --> */}
        <div class="content container-fluid">
          {/* <!-- Page Header --> */}
          <div class="page-header pb-0 mb-0 border-0">
            <div class="flex-between align-items-center">
              <div>
                <h1 className="page-header-title" style={{ textAlign: "left" }}>
                  Dashboard
                </h1>
                {/* <p>Welcome message.</p> */}
              </div>
            </div>
          </div>

          <div>
            <div className="row">
              <div className="col-sm-6 col-12">
                {" "}
                <AsyncPaginate
                  value={userselete}
                  className="liceninputv2"
                  // placeholder="location"
                  placeholder="User"
                  loadOptions={loadOptionsPalyer}
                  onChange={setuserselete}
                  additional={{
                    page: 1,
                  }}
                />
              </div>
              <div className="col-sm-6 col-12">
                <button
                  className="btn btn-primary"
                  onClick={() => hendelToprocess()}
                >
                  Process Order
                </button>{" "}
              </div>
            </div>
          </div>

          {/* <!-- End Page Header --> */}

          {/* <!-- Business Analytics --> */}
          <div class="card mb-2">
            <div class="card-body">
              {/* <div class="row flex-between align-items-center g-2 mb-3">
                <div class="col-sm-6">
                  <h4 class="d-flex align-items-center text-capitalize gap-10 mb-0">
                    <img
                      src="https://ganeshji.online/public/assets/back-end/img/business_analytics.png"
                      alt=""
                    />
                    Business analytics
                  </h4>
                </div>
                <div class="col-sm-6 d-flex justify-content-sm-end">
                  <select
                    class="custom-select w-auto"
                    name="statistics_type"
                    onchange="order_stats_update(this.value)"
                  >
                    <option value="overall">Overall statistics</option>
                    <option value="today">Todays Statistics</option>
                    <option value="this_month">This Months Statistics</option>
                  </select>
                </div>
              </div> */}
              {/* <div class="row g-2" id="order_stats">
                <div class="col-sm-6 col-lg-3 mt-15">
                  
                  <a class="order-stats order-stats_pending" href="#">
                    <div
                      class="order-stats__content"
                      style={{ textAlign: "left" }}
                    >
                      <img
                        width="20"
                        src="https://ganeshji.online/public/assets/back-end/img/pending.png"
                        alt=""
                      />
                      <h6 class="order-stats__subtitle">Pending</h6>
                    </div>
                    <span class="order-stats__title">
                      {deshboard?.pendingOrder}
                    </span>
                  </a>
             
                </div>

                <div class="col-sm-6 col-lg-3 mt-15">
                  
                  <a class="order-stats order-stats_confirmed" href="#">
                    <div
                      class="order-stats__content"
                      style={{ textAlign: "left" }}
                    >
                      <img
                        width="20"
                        src="https://ganeshji.online/public/assets/back-end/img/confirmed.png"
                        alt=""
                      />
                      <h6 class="order-stats__subtitle">Confirmed</h6>
                    </div>
                    <span class="order-stats__title">
                      {deshboard?.confirmOrder}
                    </span>
                  </a>
               
                </div>

                <div class="col-sm-6 col-lg-3 mt-15">
                  
                  <a class="order-stats order-stats_packaging" href="#">
                    <div
                      class="order-stats__content"
                      style={{ textAlign: "left" }}
                    >
                      <img
                        width="20"
                        src="https://ganeshji.online/public/assets/back-end/img/packaging.png"
                        alt=""
                      />
                      <h6 class="order-stats__subtitle">Packaging</h6>
                    </div>
                    <span class="order-stats__title">
                      {deshboard?.packagingOrder}
                    </span>
                  </a>
                 
                </div>

                <div class="col-sm-6 col-lg-3 mt-15">
                  
                  <a class="order-stats order-stats_out-for-delivery" href="#">
                    <div
                      class="order-stats__content"
                      style={{ textAlign: "left" }}
                    >
                      <img
                        width="20"
                        src="https://ganeshji.online/public/assets/back-end/img/out-of-delivery.png"
                        alt=""
                      />
                      <h6 class="order-stats__subtitle">Out for delivery</h6>
                    </div>
                    <span class="order-stats__title">
                      {deshboard?.outofdeliveryOrder}
                    </span>
                  </a>
                
                </div>

                <div class="col-sm-6 col-lg-3 mt-15">
                  <div
                    class="order-stats order-stats_delivered cursor-pointer"
                    onclick="location.href='#'"
                  >
                    <div
                      class="order-stats__content"
                      style={{ textAlign: "left" }}
                    >
                      <img
                        width="20"
                        src="https://ganeshji.online/public/assets/back-end/img/delivered.png"
                        alt=""
                      />
                      <h6 class="order-stats__subtitle">Delivered</h6>
                    </div>
                    <span class="order-stats__title">
                      {deshboard?.pendingOrder}
                    </span>
                  </div>
                </div>

                <div class="col-sm-6 col-lg-3 mt-15">
                  <div
                    class="order-stats order-stats_canceled cursor-pointer"
                    onclick="location.href='#'"
                  >
                    <div
                      class="order-stats__content"
                      style={{ textAlign: "left" }}
                    >
                      <img
                        width="20"
                        src="https://ganeshji.online/public/assets/back-end/img/canceled.png"
                        alt=""
                      />
                      <h6 class="order-stats__subtitle">Canceled</h6>
                    </div>
                    <span class="order-stats__title h3">
                      {deshboard?.canceledOrder}
                    </span>
                  </div>
                </div>

                <div class="col-sm-6 col-lg-3 mt-15">
                  <div
                    class="order-stats order-stats_returned cursor-pointer"
                    onclick="location.href='#'"
                  >
                    <div
                      class="order-stats__content"
                      style={{ textAlign: "left" }}
                    >
                      <img
                        width="20"
                        src="https://ganeshji.online/public/assets/back-end/img/returned.png"
                        alt=""
                      />
                      <h6 class="order-stats__subtitle">Returned</h6>
                    </div>
                    <span class="order-stats__title h3">
                      {deshboard?.returnOrder}
                    </span>
                  </div>
                </div>

                <div class="col-sm-6 col-lg-3 mt-15">
                  <div
                    class="order-stats order-stats_failed cursor-pointer"
                    onclick="location.href='#'"
                  >
                    <div
                      class="order-stats__content"
                      style={{ textAlign: "left" }}
                    >
                      <img
                        width="20"
                        src="https://ganeshji.online/public/assets/back-end/img/confirmed.png"
                        alt=""
                      />
                      <h6 class="order-stats__subtitle">Complete Order</h6>
                    </div>
                    <span class="order-stats__title h3">
                      {deshboard?.completeOrder}
                    </span>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {/* <!-- End Business Analytics --> */}

          {/* <!-- Admin Wallet --> */}
          <div class="card mb-3">
            <div class="card-body">
              <h4 class="d-flex align-items-center text-capitalize gap-10 mb-3">
                <img
                  width="20"
                  class="mb-1"
                  src="https://ganeshji.online/public/assets/back-end/img/admin-wallet.png"
                  alt=""
                />
                Wallet
              </h4>

              <div class="row g-2" id="order_stats">
                <div class="col-lg-4">
                  {/* <!-- Card --> */}
                  <div class="card h-100 d-flex justify-content-center align-items-center">
                    <div class="card-body d-flex flex-column gap-10 align-items-center justify-content-center">
                      <img
                        width="48"
                        class="mb-2"
                        src="https://ganeshji.online/public/assets/back-end/img/inhouse-earning.png"
                        alt=""
                      />
                      <h3 class="for-card-count mb-0 fz-24">₹0.00</h3>
                      <div class="text-capitalize mb-30">In-house earning</div>
                    </div>
                  </div>
                  {/* <!-- End Card --> */}
                </div>
                <div class="col-lg-8">
                  <div class="row g-2">
                    <div class="col-md-6 mt-15">
                      <div class="card card-body h-100 justify-content-center">
                        <div class="d-flex gap-2 justify-content-between align-items-center">
                          <div class="d-flex flex-column align-items-start">
                            <h3 class="mb-1 fz-24">
                              ₹{get_profile?.vendorWallet}
                            </h3>
                            <div class="text-capitalize mb-0">
                              Vendor Wallet
                            </div>
                          </div>
                          <div>
                            <img
                              width="40"
                              class="mb-2"
                              src="https://ganeshji.online/public/assets/back-end/img/ce.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 mt-15">
                      <div class="card card-body h-100 justify-content-center">
                        <div class="d-flex gap-2 justify-content-between align-items-center">
                          <div class="d-flex flex-column align-items-start">
                            <h3 class="mb-1 fz-24">₹0</h3>
                            <div class="text-capitalize mb-0">
                              Delivery charge earned
                            </div>
                          </div>
                          <div>
                            <img
                              width="40"
                              class="mb-2"
                              src="https://ganeshji.online/public/assets/back-end/img/dce.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 mt-15">
                      <div class="card card-body h-100 justify-content-center">
                        <div class="d-flex gap-2 justify-content-between align-items-center">
                          <div class="d-flex flex-column align-items-start">
                            <h3 class="mb-1 fz-24">₹0.00</h3>
                            <div class="text-capitalize mb-0">
                              Total tax collected
                            </div>
                          </div>
                          <div>
                            <img
                              width="40"
                              class="mb-2"
                              src="https://ganeshji.online/public/assets/back-end/img/ttc.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 mt-15">
                      <div class="card card-body h-100 justify-content-center">
                        <div
                          class="d-flex gap-2 justify-content-between align-items-center"
                          onClick={() => hendleToWithdwaralAmount()}
                        >
                          <div class="d-flex flex-column align-items-start">
                            <h3 class="mb-1 fz-24">
                              ₹{get_profile?.vendorWalletincome}
                            </h3>

                            <div class="text-capitalize mb-0">
                              Due From Admin
                            </div>
                          </div>
                          <div>
                            <button className="btn btn-primary"> Readme</button>
                            <img
                              width="40"
                              class="mb-2"
                              src="https://ganeshji.online/public/assets/back-end/img/pa.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>

          {/* <button >
            withdrwawl Test{" "}
          </button> */}
          {/* <!-- End Admin Wallet --> */}

          <div class="row g-1">
            {/* <div class="col-12">
     
              <div class="card">
        
                <div class="card-body">
                  <div class="row g-2 align-items-center">
                    <div class="col-md-6 mt-15">
                      <h4 class="d-flex align-items-center text-capitalize gap-10 mb-0">
                        <img
                          src="https://ganeshji.online/public/assets/back-end/img/earning_statictics.png"
                          alt=""
                        />
                        Earning statistics
                      </h4>
                    </div>
                    <div class="col-md-6 mt-15 d-flex justify-content-md-end">
                      <ul class="option-select-btn">
                        <li>
                          <label>
                            <input
                              type="radio"
                              name="statistics2"
                              hidden=""
                              checked=""
                            />
                            <span
                              data-earn-type="yearEarn"
                              onclick="earningStatisticsUpdate(this)"
                            >
                              This Year
                            </span>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input type="radio" name="statistics2" hidden="" />
                            <span
                              data-earn-type="MonthEarn"
                              onclick="earningStatisticsUpdate(this)"
                            >
                              This Month
                            </span>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input type="radio" name="statistics2" hidden="" />
                            <span
                              data-earn-type="WeekEarn"
                              onclick="earningStatisticsUpdate(this)"
                            >
                              This Week
                            </span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>

                 
                </div>
              
              </div>
            
            </div> */}

            {/* <div class="col-md-6 mt-15 col-xl-4">
             
              <div class="card h-100">
            
                <div class="card-header">
                  <h4 class="d-flex align-items-center text-capitalize gap-10 mb-0">
                    <img
                      src="https://ganeshji.online/public/assets/back-end/img/top-customers.png"
                      alt=""
                    />
                    Top customer
                  </h4>
                </div>
           

           
                <div class="card-body">
                  <div class="grid-card-wrap">
                    <div class="cursor-pointer" onclick="location.href='#'">
                      <div class="grid-card">
                        <div class="text-center">
                          <img
                            class="avatar rounded-circle avatar-lg"
                            onerror="this.src='https://ganeshji.online/public/assets/back-end/img/160x160/img1.jpg'"
                            src="https://ganeshji.online/storage/app/public/profile/def.png/"
                          />
                        </div>

                        <h5 class="mb-0">Nipun</h5>

                        <div class="orders-count d-flex gap-1">
                          <div>Orders : </div>
                          <div>5</div>
                        </div>
                      </div>
                    </div>
                    <div class="cursor-pointer" onclick="location.href='#'">
                      <div class="grid-card">
                        <div class="text-center">
                          <img
                            class="avatar rounded-circle avatar-lg"
                            onerror="this.src='https://ganeshji.online/public/assets/back-end/img/160x160/img1.jpg'"
                            src="https://ganeshji.online/storage/app/public/profile/def.png"
                          />
                        </div>

                        <h5 class="mb-0">admin</h5>

                        <div class="orders-count d-flex gap-1">
                          <div>Orders : </div>
                          <div>1</div>
                        </div>
                      </div>
                    </div>
                    <div class="cursor-pointer" onclick="location.href='#'">
                      <div class="grid-card">
                        <div class="text-center">
                          <img
                            class="avatar rounded-circle avatar-lg"
                            onerror="this.src='https://ganeshji.online/public/assets/back-end/img/160x160/img1.jpg'"
                            src="https://ganeshji.online/storage/app/public/profile/def.png"
                          />
                        </div>

                        <h5 class="mb-0">test</h5>

                        <div class="orders-count d-flex gap-1">
                          <div>Orders : </div>
                          <div>1</div>
                        </div>
                      </div>
                    </div>
                    <div class="cursor-pointer" onclick="location.href='#'">
                      <div class="grid-card">
                        <div class="text-center">
                          <img
                            class="avatar rounded-circle avatar-lg"
                            onerror="this.src='https://ganeshji.online/public/assets/back-end/img/160x160/img1.jpg'"
                            src="https://ganeshji.online/storage/app/public/profile/def.png"
                          />
                        </div>

                        <h5 class="mb-0">ram</h5>

                        <div class="orders-count d-flex gap-1">
                          <div>Orders : </div>
                          <div>1</div>
                        </div>
                      </div>
                    </div>
                    <div class="cursor-pointer" onclick="location.href='#'">
                      <div class="grid-card">
                        <div class="text-center">
                          <img
                            class="avatar rounded-circle avatar-lg"
                            onerror="this.src='https://ganeshji.online/public/assets/back-end/img/160x160/img1.jpg'"
                            src="https://ganeshji.online/storage/app/public/profile/def.png"
                          />
                        </div>

                        <h5 class="mb-0">vijay2</h5>

                        <div class="orders-count d-flex gap-1">
                          <div>Orders : </div>
                          <div>1</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
           
              </div>
              
            </div>

          
            <div class="col-md-6 mt-15 col-xl-4">
              <div class="card h-100">
                 
                <div class="card-header gap-10">
                  <h4 class="d-flex align-items-center text-capitalize gap-10 mb-0">
                    <img
                      src="https://ganeshji.online/public/assets/back-end/img/top-selling-store.png"
                      alt=""
                    />
                    Most Popular Stores
                  </h4>
                </div>
              
                <div class="card-body">
                  <div class="grid-item-wrap">
                    <a href="#" class="grid-item">
                      <div class="d-flex align-items-center gap-10">
                        <img
                          onerror="this.src='https://ganeshji.online/public/assets/back-end/img/160x160/img1.jpg'"
                          src="https://ganeshji.online/storage/app/public/shop/2023-09-03-64f49456c4f27.png"
                          class="avatar rounded-circle avatar-sm"
                        />

                        <h5 class="shop-name">Golden Computers</h5>
                      </div>
                      <div class="d-flex align-items-center gap-2">
                        <h5 class="shop-sell c2">5</h5>
                        <img
                          src="https://ganeshji.online/public/assets/back-end/img/love.png"
                          alt=""
                        />
                      </div>
                    </a>
                  </div>
                </div>
               
              </div>
            </div>
           

            <div class="col-md-6 mt-15 col-xl-4">
              
              <div class="card h-100">
              
                <div class="card-header gap-10">
                  <h4 class="d-flex align-items-center text-capitalize gap-10 mb-0">
                    <img
                      width="20"
                      src="https://ganeshji.online/public/assets/back-end/img/shop-info.png"
                      alt=""
                    />
                    Top selling store
                  </h4>
                </div>
               

               
                <div class="card-body">
                  <div class="grid-item-wrap">
                    <div class="cursor-pointer" onclick="location.href='#'">
                      <div class="grid-item">
                        <div class="d-flex align-items-center gap-10">
                          <img
                            class="avatar rounded-circle avatar-sm"
                            onerror="this.src='https://ganeshji.online/public/assets/back-end/img/160x160/img1.jpg'"
                            src="https://ganeshji.online/storage/app/public/shop/2023-09-03-64f49456c4f27.png"
                          />

                          <h5 class="shop-name">Golden Computers</h5>
                        </div>
                        <div class="d-flex align-items-center gap-2">
                          <h5 class="shop-sell">₹2,880.00</h5>
                          <img
                            src="https://ganeshji.online/public/assets/back-end/img/cart.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div class="cursor-pointer" onclick="location.href='#'">
                      <div class="grid-item">
                        <div class="d-flex align-items-center gap-10">
                          <img
                            class="avatar rounded-circle avatar-sm"
                            onerror="this.src='https://ganeshji.online/public/assets/back-end/img/160x160/img1.jpg'"
                            src="https://ganeshji.online/storage/app/public/shop/2023-08-09-64d33cd893ebf.png"
                          />

                          <h5 class="shop-name">Radhika Fashion</h5>
                        </div>
                        <div class="d-flex align-items-center gap-2">
                          <h5 class="shop-sell">₹0.00</h5>
                          <img
                            src="https://ganeshji.online/public/assets/back-end/img/cart.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div class="cursor-pointer" onclick="location.href='#'">
                      <div class="grid-item">
                        <div class="d-flex align-items-center gap-10">
                          <img
                            class="avatar rounded-circle avatar-sm"
                            onerror="this.src='https://ganeshji.online/public/assets/back-end/img/160x160/img1.jpg'"
                            src="https://ganeshji.online/storage/app/public/shop/2023-10-04-651d2941c7fd9.png"
                          />

                          <h5 class="shop-name">sheela fashion</h5>
                        </div>
                        <div class="d-flex align-items-center gap-2">
                          <h5 class="shop-sell">₹0.00</h5>
                          <img
                            src="https://ganeshji.online/public/assets/back-end/img/cart.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div class="cursor-pointer" onclick="location.href='#'">
                      <div class="grid-item">
                        <div class="d-flex align-items-center gap-10">
                          <img
                            class="avatar rounded-circle avatar-sm"
                            onerror="this.src='https://ganeshji.online/public/assets/back-end/img/160x160/img1.jpg'"
                            src="https://ganeshji.online/storage/app/public/shop/2023-10-16-652d0d0b63d80.png"
                          />

                          <h5 class="shop-name">creato</h5>
                        </div>
                        <div class="d-flex align-items-center gap-2">
                          <h5 class="shop-sell">₹0.00</h5>
                          <img
                            src="https://ganeshji.online/public/assets/back-end/img/cart.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div class="cursor-pointer" onclick="location.href='#'">
                      <div class="grid-item">
                        <div class="d-flex align-items-center gap-10">
                          <img
                            class="avatar rounded-circle avatar-sm"
                            onerror="this.src='https://ganeshji.online/public/assets/back-end/img/160x160/img1.jpg'"
                            src="https://ganeshji.online/storage/app/public/shop/2023-08-01-64c88b534e5e8.png"
                          />

                          <h5 class="shop-name">test</h5>
                        </div>
                        <div class="d-flex align-items-center gap-2">
                          <h5 class="shop-sell">₹0.00</h5>
                          <img
                            src="https://ganeshji.online/public/assets/back-end/img/cart.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
             
            </div> */}

            {/* <div class="col-md-6 mt-15 col-xl-4">
              
              <div class="card h-100">
                
                <div class="card-header">
                  <h4 class="d-flex align-items-center text-capitalize gap-10 mb-0">
                    <img
                      src="https://ganeshji.online/public/assets/back-end/img/most-popular-product.png"
                      alt=""
                    />
                    Most popular products
                  </h4>
                </div>
                
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <div class="grid-card-wrap"></div>
                    </div>
                  </div>
                </div>
                
              </div>
            
            </div>

            <div class="col-md-6 mt-15 col-xl-4">
              
              <div class="card h-100">   
                <div class="card-header gap-10">
                  <h4 class="d-flex align-items-center text-capitalize gap-10 mb-0">
                    <img
                      width="20"
                      src="https://ganeshji.online/public/assets/back-end/img/top-selling-product.png"
                      alt=""
                    />
                    Top selling products
                  </h4>
                </div>
               
                <div class="card-body">
                  <div class="grid-item-wrap">
                    <div class="cursor-pointer" onclick="location.href='#'">
                      <div class="grid-item px-0 bg-transparent">
                        <div class="d-flex gap-10">
                          <img
                            src="https://ganeshji.online/storage/app/public/product/thumbnail/2023-09-03-64f496d855fd0.png"
                            class="avatar avatar-lg rounded avatar-bordered"
                            onerror="this.src='https://ganeshji.online/public/assets/back-end/img/160x160/img2.jpg'"
                            alt="HP USB 3.1 Flash Drive 64GB 796W image"
                          />
                          <div class="title-color">
                            HP USB 3.1 Flash Dri ...
                          </div>
                        </div>

                        <div class="orders-count py-2 px-3 d-flex gap-1">
                          <div>Sold : </div>
                          <div class="sold-count">4</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               
              </div>
     
            </div>

            <div class="col-md-6 mt-15 col-xl-4">
             
              <div class="card h-100">
    
                <div class="card-header">
                  <h4 class="d-flex align-items-center text-capitalize gap-10 mb-0">
                    <img
                      src="https://ganeshji.online/public/assets/back-end/img/top-customers.png"
                      alt=""
                    />
                    Top Delivery Man
                  </h4>
                </div>
               
                <div class="card-body">
                  <div class="grid-card-wrap"></div>
                </div>
                
              </div>
          
            </div> */}
          </div>
        </div>
      </main>
    </div>
  );
}

export default Deshboard;
