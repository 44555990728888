import React, { useEffect, useState, useRef } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";

import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";

import ReactToPrint from "react-to-print";

// import { useRouter } from "next/router";
// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
import HeadingBack from "../HeadingBack";
import { useNavigate } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
// import HeadingBack from "../../HeadingBack";

function OrderCom() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = navigate;
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");
  const componentRef = useRef();
  const { confirm } = Modal;
  const page = navigate?.query?.page == null ? 1 : navigate?.query?.page;
  const search = navigate?.query?.search == null ? "" : navigate?.query?.search;

  const order_list = useSelector((state) =>
    state?.allapi?.get_seller_transaction_list
      ? state?.allapi?.get_seller_transaction_list
      : {}
  );
  const order_details = useSelector((state) =>
    state?.allapi?.order_details ? state?.allapi?.order_details : {}
  );
  const get_invoice = useSelector((state) =>
    state?.allapi?.get_invoice ? state?.allapi?.get_invoice : {}
  );

  const [pages, setPage] = useState(1);

  const listdata = order_list?.docs ? order_list?.docs : [];

  useEffect(() => {
    // if (search) {
    //   dispatch(allapiAction.getSearchOrdervendor({ page: page, key: search }));
    // } else {
    dispatch(allapiAction.getuserOrdernew(page));
    // }
    dispatch(allapiAction.getInvoice());
    return () => {};
  }, [location]);

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const hendleDelete = (e) => {
    console.log(e);
    // deleteProduct
    // setShowDelete(false);
    dispatch(allapiAction.deleteProduct({ productId: e, pageNo: page }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);
  const [statusDataBoxsure, setstatusDatasure] = useState(false);
  const [statusDataBoxView, setstatusDataBoxView] = useState(false);
  const [statusDataBoxReturn, setstatusDataBoxReturn] = useState(false);
  const [productID, setproductID] = useState("");
  const [statusUpTrStatus, setstatusUpTrStatus] = useState("");
  const [statusUpTrId, setstatusUpTrId] = useState("");
  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    setuserData(e);
    setproductID(e);
    dispatch(allapiAction.getuserOrderDetailsProduction({ _id: e?._id }));
  };
  const hendleToViewMOrder = (e) => {
    setstatusDatasure(true);
    setuserData(e);
    setproductID(e);
    // dispatch(allapiAction.OrderDeliveryStatusDoneByuser({ _id: e?._id }));
  };
  const hendleToViewProduct = (e) => {
    setstatusDataBoxView(true);
    setuserData(e);
    setproductID(e);
    dispatch(allapiAction.getuserOrderDetailsProduction({ _id: e?._id }));
    // dispatch(allapiAction.getuserOrderDetails({ _id: route?.query?.id }));
  };
  const hendleToViewReturn = (e) => {
    setstatusDataBoxReturn(true);
    // setuserData(e);
    setstatusUpTrId(e);
    // dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };
  const hendleSubmitReturn = (e) => {
    setstatusDataBoxReturn(false);
    // dispatch(
    //   allapiAction.cancelOrderByAdmin({
    //     _id: statusUpTrId?._id,
    //     user_id: statusUpTrId?.user_id?._id,
    //     description: statusUpTrStatus,
    //     page: page,
    //   })
    // );
    setstatusUpTrStatus("");
    // dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };

  const shippingCharge = parseInt(order_details?.delivery_charges);
  console.log(order_details?.orderproductsList);

  // const totalSubtotalPricegstdd =
  //   (order_details.sub_total * order_details?.gst) / 100;

  const totalPrice = order_details?.product_price; // Total price inclusive of GST
  const gstRate = order_details?.gst; // GST rate (5%)

  const basePrice = totalPrice / (1 + gstRate / 100);

  console.log("Base Price (Net Amount): $" + basePrice.toFixed(2));

  const basePriceTotal = basePrice * order_details?.itme_count;

  const totalSubtotalPricegstdd = order_details?.orderproductsList?.reduce(
    (total, obj) => {
      return total + obj.sub_total / (1 + obj?.gst / 100);
    },
    0
  );

  console.log(totalSubtotalPricegstdd);

  const perProgst = totalPrice - basePrice;
  console.log(perProgst?.toFixed(2));
  console.log(basePriceTotal?.toFixed(2));

  console.log(order_details);
  const totalgst = order_details?.sub_total - basePriceTotal;
  console.log(totalgst?.toFixed(2));

  const totalSubtotalPricegst = totalSubtotalPricegstdd;

  const totalAmount = order_details?.sub_total + shippingCharge;
  // const totalAmount =
  //   order_details?.sub_total + shippingCharge + totalSubtotalPricegstdd;

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    // dispatch(
    //   allapiAction.addFoundByAdmin({
    //     id: userData?._id,
    //     amount: statusData?.amount,

    //     pageNumber: page,
    //   })
    // );

    setstatusDataBox(false);
  };

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: userId,
    //     status: userId,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };

  const handleOkShow = async () => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: userId,
    //     status: userId,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    const response = await dispatch(
      allapiAction.OrderDeliveryStatusDoneByuser({ _id: userData?._id })
    );
    if (response?.success) {
      setuserId("");
      setstatusDatasure(false);
      dispatch(allapiAction.getuserOrdernew(page));
    }

    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: id,
    //     status: status,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancelsure = () => {
    setstatusDatasure(false);
  };

  const hendleToStatus = (id, e) => {
    // statusCategory

    // dispatch(allapiAction.statusProduct({ productId: id, status: e }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    // {
    //   title: "Image",
    //   dataIndex: "referId",
    //   key: "referId",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer coustomNevc"
    //           // onClick={() => navigate(`/user-details/${item?.referId}`)}
    //         >

    //           <img
    //             src={URL?.API_BASE_URL + item?.colorId?.featureImage}
    //             alt=""
    //             style={{ width: "70px" }}
    //           />

    //         </span>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Order Number",
      dataIndex: "sellCode",
      key: "sellCode",
      className: "table_action_col",
    },
    // {
    //   title: "Image",
    //   dataIndex: "image",
    //   key: "image",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <div style={{ width: "100px" }}>
    //           <img
    //             style={{ width: "100%" }}
    //             src={URL?.API_BASE_URL + item?.featureImage}
    //             alt=""
    //           />
    //         </div>
    //       </>
    //     );
    // //   },
    // },

    // {
    //   title: "Product name",
    //   dataIndex: "product_names",
    //   key: "product_names",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer coustomNevc"
    //           // onClick={() => navigate(`/user-details/${item?.referId}`)}
    //         >
    //           {item?.product_names}

    //           {/* <p> {item?.user_id?.referId}</p> */}
    //         </span>
    //       </>
    //     );
    //   },
    // },

    // {
    //   title: "Sponsor Code",
    //   dataIndex: "referByuserId",
    //   key: "referByuserId",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer coustomNevc"
    //           // onClick={() => navigate(`/user-details/${item?.referId}`)}
    //         >
    //           {item?.user_id?.referByuserId?.fullName}

    //           <p> {item?.user_id?.referByuserId?.referId}</p>
    //         </span>
    //       </>
    //     );
    //   },
    // },

    // {
    //   title: "Color",
    //   dataIndex: "phone",
    //   key: "phone",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer "
    //           // onClick={() => navigate(`/user-details/${item?.referId}`)}
    //         >
    //           {item?.colorId?.color}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Name",
    //   dataIndex: "title",
    //   key: "title",
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <Tooltip title={item?.status}>
    //           <button
    //             className={
    //               item?.status == "Active"
    //                 ? "success-button ml-3"
    //                 : "delete-button ml-3"
    //             }
    //             onClick={() =>
    //               hendleToStatus(
    //                 item?._id,
    //                 item?.status == "Active" ? "Inactive" : "Active"
    //               )
    //             }
    //           >
    //             {item?.status}
    //           </button>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Upline Id",
    //   dataIndex: "referBy",
    //   key: "referBy",
    // },
    // {
    //   title: "Number",
    //   dataIndex: "phone",
    //   key: "phone",
    // },
    // {
    //   title: "Password",
    //   dataIndex: "passwordPlan",
    //   key: "passwordPlan",
    // },
    // {
    //   title: "Wallet",
    //   dataIndex: "balance",
    //   key: "balance",
    // },
    {
      title: "Amount",
      dataIndex: "sub_total",
      key: "sub_total",
    },
    // {
    //   title: "Paid Amount",
    //   dataIndex: "paidAmount",
    //   key: "paidAmount",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.paidAmount}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Remaining Amount",
    //   dataIndex: "reaminingAmount",
    //   key: "reaminingAmount",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.sub_total - item?.paidAmount}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Reffer Code",
    //   dataIndex: "referCode",
    //   key: "referCode",
    // },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {moment(item?.createdAt).format(" DD/MM/YYYY ")}
            </span>
          </>
        );
      },
    },
    //     {
    //       title: "Status",
    //       dataIndex: "status",
    //       key: "status",
    //       className: "table_action_col",
    //       render: (value, item, index) => {

    //         const userRank =
    //         item?.status == '0'
    //           ? 'free'
    //           : item?.status == '1'
    //           ? 'FRANCHISE'
    //           : item?.status == '2'
    //           ? 'BARON'
    //           : item?.status == '3'
    //           ? 'COUNT'
    //           : item?.status == '4'
    //           ? 'MARQUISE'
    //           : item?.status == '4'
    //           ? 'DUKE'
    //           : 'ARCH DUKE'
    //         return (
    //           <>

    // <span
    //               className="cursor-pointer"

    //             >
    //              {userRank}
    //             </span>

    //           </>
    //         );
    //       },
    //     },

    // {
    //   title: "Payment Mode",
    //   dataIndex: "payment_mode_name",
    //   key: "payment_mode_name",
    // },
    {
      title: "Delivery status",
      dataIndex: "delivery_status",
      key: "delivery_status",
    },
    // {
    //   title: "Order return",
    //   dataIndex: "orderretune",
    //   key: "orderretune",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.orderretune == "true" ? "Yes" : "No"}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact">
              <span
                className="cursor-pointer"
                onClick={() => navigate(`/users/contact/${item?.id}`)}
              >
                <SvgIcon.contacts className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
            {/* <Tooltip title="delete">
              <button
                className="filter-button ml-3"
                onClick={() => showModal(item?.id)}
              >
                Delete
              </button>
            </Tooltip> */}

            <Tooltip title="Invoice">
              <span
                className="cursor-pointer btn btn-outline-primary mr-5"
                onClick={() => hendleToViewM(item)}
              >
                Invoice
              </span>
            </Tooltip>

            <Tooltip title="View">
              <span
                className="cursor-pointer btn btn-outline-success"
                // onClick={() => hendleToViewM(item)}
                onClick={() => hendleToViewProduct(item)}
              >
                <img
                  src="https://ganeshji.online/public/assets/back-end/img/eye.svg"
                  class="svg"
                  alt=""
                />
              </span>
            </Tooltip>

            <Tooltip title="Invoice">
              <span
                className="cursor-pointer btn btn-outline-primary mr-5 mt-5"
                onClick={() => hendleToViewMOrder(item)}
              >
                Complete Order
              </span>
            </Tooltip>
            {/* <Tooltip title="Invoice">
              <button
                className="filter-button ml-3  mt-5"
                onClick={() => hendleToViewM(item)}
              >
                Invoice
              </button>
            </Tooltip>
            <Tooltip title="View">
              <button
                className="filter-button ml-3  mt-5"
                onClick={() => hendleToViewProduct(item)}
              >
                View
              </button>
            </Tooltip> */}
          </>
        );
      },
    },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/seller/sale?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);

    navigate("/accounts/order?page=" + e + "&&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };
  return (
    <div>
      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>
      <Modal
        title="Delivry Status"
        open={statusDataBoxsure}
        onOk={handleOkShow}
        onCancel={handleCancelsure}
      >
        <p>Are you sure you want to complate</p>
      </Modal>

      <Modal
        title="Inovice"
        centered
        open={statusDataBox}
        // onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={1000}
      >
        <div
          id="kt_app_content"
          className="app-content flex-column-fluid"
          ref={componentRef}
        >
          <div class="container">
            <div class="row">
              <div class="col-12 text-center bg-secondary py-3">
                <h3>
                  <b>Invoice Paper</b>
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center py-2 bg-light">
                <p class="p-0 m-0">
                  {" "}
                  <b>Invoice No</b> : {order_details?.sellCode}
                </p>
                <p class="p-0 m-0">
                  <b>Date</b> :{/* 04 Mar, 2023 */}
                  {moment(productID?.createdAt).format("DD MMM,  YYYY")}
                </p>
                <p class="p-0 m-0">
                  <b>Vendor</b> : {order_details?.orderproductsList && order_details?.orderproductsList[0]?.vendor_id?.displayName}
                </p>
                <p class="text-left">
                  <b> User Id </b>: {order_details?.user_id?.referId}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h4>
                  <b>Client Information:</b>
                </h4>
                <p class="p-0 m-0">
                  <b>Full Name</b>: {order_details?.address_id?.name}
                </p>
                {/* <p class="p-0 m-0">
                  <b>Last Name:</b>
                </p> */}
              </div>
              <div class="col-6">
                <h4>
                  <b>Peyment Details :</b>
                </h4>
                <p class="p-0 m-0">
                  <b>Payment Status</b> : Fully Paid
                </p>
                <p class="p-0 m-0">
                  <b>Payment Method</b> : Wallet
                </p>
              </div>
            </div>
            <div class="row mx-2">
              <div class="col-12 mt-2 text-center text-light bg-danger py-3">
                <h3>
                  <b>Payment Invoice</b>
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                {/* <table class="table table-responsive">
                  <thead class="thead-dark" style={{minWidth:"100%"}}>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">First</th>
                      <th scope="col">Last</th>
                      <th scope="col">Handle</th>
                      <th scope="col">Handle</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Mark</td>
                      <td>Otto</td>
                      <td>@mdo</td>
                      <td>@mdo</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Jacob</td>
                      <td>Thornton</td>
                      <td>@fat</td>
                      <td>@fat</td>
                    </tr>
                  </tbody>
                </table> */}
                <div className="table-responsive border-bottom mb-9">
                  <table className="table  align-middle table-row-dashed fs-6 gy-5 mb-0">
                    <thead>
                      <tr className="border-bottom thead-dark fs-6 fw-bold text-muted">
                        <th>S.No</th>
                        <th className="min-w-175px pb-2">Item</th>
                        <th className="min-w-70px text-end pb-2">Color</th>
                        <th className="min-w-70px text-end pb-2">Size</th>
                        <th className="min-w-70px text-end pb-2">Unit Cost</th>
                        <th className="min-w-80px text-end pb-2">Quantity</th>
                        <th className="min-w-80px text-end pb-2">
                          Net Amount(₹)
                        </th>
                        <th className="min-w-80px text-end pb-2">Tax(₹)</th>

                        <th className="min-w-100px text-end pb-2">Amount</th>
                      </tr>
                    </thead>
                    <tbody className="fw-semibold invotb text-gray-600">
                      {order_details?.orderproductsList &&
                        order_details?.orderproductsList?.map((data, i) => {
                          const totalPrice = data?.product_price;
                          const gstRate = data?.gst;
                          const basePrice = totalPrice / (1 + gstRate / 100);
                          const perProgst = totalPrice - basePrice;

                          const netAmount = basePrice * data?.itme_count;

                          const taxAmount = perProgst * data?.itme_count;

                          return (
                            <tr>
                              <td className="">{i + 1}</td>
                              <td>
                                <div className="d-flex ">
                                  <div className="">
                                    <div className="fw-bold">
                                      {data?.product_names}
                                    </div>
                                    <div className="fs-7 text-muted"></div>
                                  </div>
                                </div>
                              </td>

                              <td className="text-end">
                                {" "}
                                {data?.colorId?.color}
                              </td>
                              <td className="text-end">
                                {" "}
                                {data?.sizeId?.size}
                              </td>
                              <td className="text-end">
                                {basePrice?.toFixed(2)}
                              </td>
                              <td className="text-end"> {data?.itme_count}</td>
                              <td className="text-end">
                                {" "}
                                {netAmount?.toFixed(2)}
                              </td>

                              <td className="text-end">
                                {" "}
                                {taxAmount?.toFixed(2)}
                              </td>

                              <td className="text-end">₹ {data?.sub_total}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 bg-secondary text-black">
                <h4 class="py-2">
                  <b>Address</b>
                </h4>
                <p class="p-0 m-0  text-black">
                  {order_details?.address_id?.address},
                </p>
                <p class="p-0 m-0  text-black">
                  ZIP : {order_details?.address_id?.pincode}
                </p>
                <p class="p-0 m-0  text-black">
                  Phone :{order_details?.address_id?.phone}
                </p>
                <p class="p-0 m-0  text-black">
                  E-mail : {order_details?.address_id?.email}
                </p>
              </div>
              <div class="col-6 text-right">
                <h5 class="py-2">
                  Sub Total Amount :₹
                  {totalSubtotalPricegstdd?.toFixed(2)}
                </h5>
                <h5 class="py-2">
                  Tax :₹{(totalAmount - totalSubtotalPricegstdd)?.toFixed(2)}
                </h5>
                <h5 class="py-2">Shipping :₹{shippingCharge}</h5>
                <h4 class="py-2">Grand Total :₹{totalAmount}</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6"></div>
          <div className="col-6">
            <ReactToPrint
              trigger={() => (
                <Button variant="primary" onClick={hendleDelete}>
                  Print / Download
                </Button>
                // <button className='bg-blue-500 ml-5 text-white font-bold py-2 px-8 rounded shadow border-2 border-blue-500 hover:bg-transparent hover:text-blue-500 transition-all duration-300'>
                //   Print / Download
                // </button>
              )}
              content={() => componentRef.current}
            />
          </div>
        </div>
      </Modal>
      <Modal
        title="Order Details"
        centered
        open={statusDataBoxView}
        onOk={() => setstatusDataBoxView(false)}
        onCancel={() => setstatusDataBoxView(false)}
        width={1200}
      >
        <div className="">
          <div>
            {/* <h6>Name</h6>
                    <p>{roleID?.name}</p> */}
            {/* <div className='productDetailsImg'>
                      <img src={URL.API_BASE_URL + roleID?.featureImage} alt='' />
                    </div> */}
            <div className="row">
              <div className="col-4">
                <h6>Sale id : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.sellCode}</p>
              </div>

              <div className="col-4">
                <h6>payment mode name : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.payment_mode_name}</p>
              </div>

              <div className="col-4">
                <h6>sub total : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.sub_total}</p>
              </div>
            </div>
            <hr />
            <h3>User Details</h3>
            <div className="row">
              {/* <div className="col-4">
                <h6> user id : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.user_id?._id}</p>
              </div> */}

              <div className="col-4">
                <h6>User id : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.user_id?.referId}</p>
              </div>

              <div className="col-4">
                <h6>full name : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.user_id?.fullName}</p>
              </div>
              <div className="col-4">
                <h6>Email : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.user_id?.email}</p>
              </div>
            </div>
            {/* <hr /> */}
            {/* <h3>shipping address</h3>
            <div className="row">
              <div className="col-4">
                <h6> name : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.address_id?.name}</p>
              </div>

              <div className="col-4">
                <h6>email : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.email}</p>
              </div>

              <div className="col-4">
                <h6>phone : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.phone}</p>
              </div>
              <div className="col-4">
                <h6>address : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.address}</p>
              </div>
              <div className="col-4">
                <h6>city : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.address_id?.city}</p>
              </div>
              <div className="col-4">
                <h6>state : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.address_id?.state}</p>
              </div>
              <div className="col-4">
                <h6>pincode : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.pincode}</p>
              </div>
            </div> */}

            <hr />
            <h3>Order Product</h3>
            {/* {order_details?.orderproductsList &&
              order_details?.orderproductsList?.map((data, i) => {
                return ( */}
            <div className="table-responsive border-bottom mb-9">
              <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                <thead>
                  <tr className="border-bottom fs-6 fw-bold text-muted">
                    <th>S.No</th>
                    <th className="min-w-175px pb-2">Item</th>
                    <th className="min-w-70px text-end pb-2">Color</th>
                    <th className="min-w-70px text-end pb-2">Size</th>
                    <th className="min-w-70px text-end pb-2">Unit Cost</th>
                    <th className="min-w-80px text-end pb-2">Quantity</th>
                    <th className="min-w-80px text-end pb-2">
                      Complete Quantity
                    </th>
                    <th className="min-w-80px text-end pb-2">Net Amount(₹)</th>
                    <th className="min-w-80px text-end pb-2">Tax(₹)</th>

                    <th className="min-w-100px text-end pb-2">Amount</th>
                  </tr>
                </thead>
                <tbody className="fw-semibold invotb text-gray-600">
                  {/* -begin::Products-*/}

                  {order_details?.orderproductsList &&
                    order_details?.orderproductsList?.map((data, i) => {
                      const totalPrice = data?.product_price;
                      const gstRate = data?.gst;
                      const basePrice = totalPrice / (1 + gstRate / 100);
                      const perProgst = totalPrice - basePrice;

                      const netAmount = basePrice * data?.itme_count;

                      const taxAmount = perProgst * data?.itme_count;

                      return (
                        <tr>
                          {/* -begin::Product-*/}
                          <td className="">{i + 1}</td>
                          <td>
                            <div className="d-flex ">
                              <div className="">
                                <div className="fw-bold">
                                  {data?.product_names}
                                </div>
                                <div className="fs-7 text-muted">
                                  {/* Delivery Date: 23/11/2022 */}
                                </div>
                              </div>
                            </div>
                          </td>
                          {/* -end::Product-*/}
                          {/* -begin::SKU-*/}
                          <td className="text-end"> {data?.colorId?.color}</td>
                          <td className="text-end"> {data?.sizeId?.size}</td>
                          <td className="text-end">
                            {" "}
                            {/* {order_details?.product_price} */}
                            {basePrice?.toFixed(2)}
                          </td>
                          <td className="text-end"> {data?.itme_count}</td>
                          <td className="text-end">
                            {" "}
                            {data?.complete_itme_count
                              ? data?.complete_itme_count
                              : 0}
                          </td>
                          <td className="text-end"> {netAmount?.toFixed(2)}</td>
                          {/* -end::SKU-*/}
                          {/* -begin::Quantity-*/}
                          <td className="text-end"> {taxAmount?.toFixed(2)}</td>

                          {/* -end::Quantity-*/}
                          {/* -begin::Total-*/}
                          <td className="text-end">₹ {data?.sub_total}</td>
                          {/* -end::Total-*/}
                        </tr>
                      );
                    })}

                  {/* <tr>
                              
                                <td className="">1</td>
                                <td>
                                  <div className="d-flex ">
                                    <div className="">
                                      <div className="fw-bold">
                                        {order_details?.product_names}
                                      </div>
                                      <div className="fs-7 text-muted">
                                      
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                
                                <td className="text-end">
                                  {" "}
                                  {order_details?.colorId?.color}
                                </td>
                                <td className="text-end">
                                  {" "}
                                  {order_details?.sizeId?.size}
                                </td>
                                <td className="text-end">
                                  
                                  {basePrice?.toFixed(2)}
                                </td>
                                <td className="text-end">
                                  {" "}
                                  {order_details?.itme_count}
                                </td>
                                <td className="text-end">
                                  {" "}
                                  {basePrice?.toFixed(2) *
                                    order_details?.itme_count}
                                </td>
                                
                                <td className="text-end">
                                  {" "}
                                  {perProgst?.toFixed(2) *
                                    order_details?.itme_count}
                                </td>

                               
                                <td className="text-end">
                                  ₹ {order_details?.sub_total}
                                </td>
                              
                              </tr> */}

                  <tr>
                    <td colSpan={9} className="text-end">
                      Shipping
                    </td>
                    <td className="text-end">₹ {shippingCharge}</td>
                  </tr>

                  <tr>
                    <td colSpan={9} className="fs-3 text-dark fw-bold text-end">
                      Grand Total
                    </td>
                    <td className="text-dark fs-3 fw-bolder text-end">
                      ₹ {totalAmount}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              {/* {order_details?.orderproductsList &&
                order_details?.orderproductsList?.map((data, i) => {
                  return (
                    <div>
                      <div className="row">
                        <div className="col-3">
                          <h6>sub order id</h6>
                        </div>
                        <div className="col-3">
                          <p>{data?.sub_order_id}</p>
                        </div>
                        <div className="col-3">
                          <h6>name</h6>
                        </div>
                        <div className="col-3">
                          <p>{data?.product_names}</p>
                        </div>
                        <div className="col-3">
                          <h6>Color</h6>
                        </div>
                        <div className="col-3">
                          <p>{data?.colorId?.color}</p>
                        </div>
                        <div className="col-3">
                          <h6>size</h6>
                        </div>
                        <div className="col-3">
                          <p>{data?.sizeId?.size}</p>
                        </div>

                        <div className="col-3">
                          <h6>product price</h6>
                        </div>
                        <div className="col-3">
                          <p>{data?.product_price}</p>
                        </div>
                        <div className="col-3">
                          <h6>item Count</h6>
                        </div>
                        <div className="col-3">
                          <p>{data?.itme_count}</p>
                        </div>
                        <div className="col-3">
                          <h6>sub total</h6>
                        </div>

                        <div className="col-3">
                          <p>{data?.sub_total}</p>
                        </div>
                        <div className="col-3">
                          <h6>Complete Quantity</h6>
                        </div>
                        <div className="col-3">
                          <p>{data?.complete_itme_count}</p>
                        </div>
                        <div className="col-3">
                          <h6>feture image</h6>
                        </div>
                        <div className="col-3">
                          <div style={{ width: "100px" }}>
                            <img
                              src={
                                URL.API_BASE_URL + data?.colorId?.featureImage
                              }
                              alt=""
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  );
                })} */}

              {/* <div className="row">
                <div className="col-3">
                  <h6>sub order id</h6>
                </div>
                <div className="col-3">
                  <p>{order_details?.sub_order_id}</p>
                </div>
                <div className="col-3">
                  <h6>name</h6>
                </div>
                <div className="col-3">
                  <p>{order_details?.product_names}</p>
                </div>
                <div className="col-3">
                  <h6>Color</h6>
                </div>
                <div className="col-3">
                  <p>{order_details?.colorId?.color}</p>
                </div>
                <div className="col-3">
                  <h6>size</h6>
                </div>
                <div className="col-3">
                  <p>{order_details?.sizeId?.size}</p>
                </div>

                <div className="col-3">
                  <h6>product price</h6>
                </div>
                <div className="col-3">
                  <p> ₹ {order_details?.product_price}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p></p>
                </div>
                <div className="col-3">
                  <h6>item Count</h6>
                </div>
                <div className="col-3">
                  <p>x {order_details?.itme_count}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p></p>
                </div>
                <div className="col-3">
                  <h6>sub total</h6>
                </div>
                <div className="col-3">
                  <p> ₹ {order_details?.sub_total}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p></p>
                </div>
                <div className="col-3">
                  <h6>Shipping</h6>
                </div>
                <div className="col-3">
                  <p>₹ {shippingCharge}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p> </p>
                </div>
                <div className="col-3">
                  <h6>Gst</h6>
                </div>
                <div className="col-3">
                  <p> ₹ {totalSubtotalPricegstdd}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p> </p>
                </div>
                <div className="col-3">
                  <h6>Grant total</h6>
                </div>
                <div className="col-3">
                  <p> ₹ {totalAmount}</p>
                </div>
                <div className="col-3">
                  <h6>feture image</h6>
                </div>
                <div className="col-3">
                  <div style={{ width: "100px" }}>
                    <img
                      src={
                        URL.API_BASE_URL + order_details?.colorId?.featureImage
                      }
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                  
                </div>
              </div> */}

              {/* <hr /> */}
            </div>
            {/* );
              })} */}

            {/* <h6>product Image</h6> */}
            {/* <div className='row'>
                      {roleID?.images &&
                        roleID?.images?.map((data: any, i: any) => {
                          console.log(data)

                          return (
                            <div className='col-3 proMultiImg'>
                              <img src={URL.API_BASE_URL + data?.img} alt='' />
                            </div>
                          )
                        })}
                    </div> */}
          </div>
        </div>
      </Modal>
      <Modal
        title="Return order"
        centered
        open={statusDataBoxReturn}
        onOk={() => hendleSubmitReturn(false)}
        onCancel={() => setstatusDataBoxReturn(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Description</Col>
            <Col span={24}>
              <Input
                name="description"
                placeholder="enter description..."
                value={statusUpTrStatus}
                onChange={(e) => {
                  setstatusUpTrStatus(e?.target?.value);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Order"}
            subHeading={`Total ${order_list?.totalDocs || 0} Order`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        {/* <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => navigate("/user/add")}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div> */}
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: order_list?.totalDocs ? order_list?.totalDocs : 0,
          }}
        />
      </div>
    </div>
  );
}

export default OrderCom;
